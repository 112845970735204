import "./wrapper.scss";
import Nav from "../Nav/Nav";
import ContentBlock from "../ContentBlock/ContentBlock";
import PhotoBlock from "../PhotoBlock/PhotoBlock";
import img1 from "../../images/watching.jpeg";
import img2 from "../../images/distance.jpeg";
import img3 from "../../images/canoe.jpeg";

function Wrapper() {
  return (
    <div className="wrapper">
      <Nav />
      <ContentBlock text="Welcome." />
      <PhotoBlock img={img1} />
      <div className="contentblock">
        <p>We must never forget that we may find meaning in life even when confronted with a hopeless situation, when facing a fate that cannot be changed. For what then matters is to bear witness to the uniquely human potential at its best, which is to transform a personal tragedy into a triumph, to turn one's predicament into a human achievement.</p>
        <p>- Viktor Frankl (1957, p. 116) "Man's Search for Meaning"</p>
      </div>
      <PhotoBlock img={img2} />
      <div className="contentblock" style={{backgroundColor: "pink"}}>
        <p>In order to continue Andra's legacy to provide support for patients with cancer, a non-profit foundation has been created. Donations <a href="https://www.paypal.com/donate/?hosted_button_id=Q5NFFNTNHDXMU" target="_blank" rel="noreferrer">can be made online securely with PayPal here</a>, using Venmo <strong>@ChasingTheLight</strong>, or may be sent to the following address:</p>
        <p>Chasing the Light<br />
        c/o Aleksandrs Kalnins, MD<br />
        2025 Hollywood Court, Wilmette, IL 60091</p>
        <p>Please make checks payable to "Chasing the Light". Donations may be designated in the memo field "Andra's Legacy Foundation"</p>
      </div>
      <PhotoBlock img={img3} />
      <div className="contentblock">
        <p className="text text--small">&copy; 2023 Andra's Legacy Foundation. Photos by Janis Kraulis.</p>
      </div>
    </div>
  );
}

export default Wrapper;
