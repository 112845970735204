import "./nav.scss";
import navLogo from "../../images/chasing-the-light--min.svg";

function Nav() {
  return <div className="nav">
    <div className="nav__img">
      <img src={navLogo} className="nav__logo" alt=""></img>
    </div>
  </div>;
}

export default Nav;