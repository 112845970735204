import "./contentblock.scss";

function ContentBlock(props) {
  return <div className="contentblock">
    {/* <p>{props.text}</p> */}
    <p>Welcome.</p>
    <p>Those touched by cancer.</p>
    <p>Those touched by metastatic breast cancer.</p>
    <p>Anyone looking for a little bit of light and hope.</p>
  </div>;
}

export default ContentBlock;