import "./photoblock.scss";

function PhotoBlock(props) {
  return (
    <div className="photoblock">
      <div
        className="photoblock__img"
        style={{
          backgroundImage: `url(${props.img})`,
        }}
      ></div>
    </div>
  );
}

export default PhotoBlock;
